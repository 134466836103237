.title {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 75%;
    text-align: center;
}

.title .title-deco2 {
    width: 2.5rem;
}

.title h2 {
    margin: 0 .5rem;
    font-size: 1.2rem;
    font-style: italic;
}

.title .title-deco2--reversed  {
   transform: rotate(180deg);
}