.mietmich-text {
    line-height: 1.4rem;
    margin-top: 2rem;
}

.mietmich-bold {
    margin-top: 2rem;
    font-weight: 700;
}

.image-gallery {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4rem;
}

.image-gallery img {
    width: 45%;
    margin: auto;
    max-width: 500px;
    margin-top: 4rem;
}

@media only screen and (max-width: 600px) {
    .image-gallery {
        flex-direction: column;
    }
    
    .image-gallery img {
        width: 95%;
    }
}