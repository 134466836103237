.faq-wrapper {
    margin-top: 2rem;
    display: grid;
    column-gap: 3rem;
    row-gap: 3rem;
    grid-template-columns: 1fr 1fr 1fr;
}

.faq-title {
 margin-bottom: 1rem;
 text-transform: none;
 font-size: 1.4rem;
}

.faq-anwer {
    line-height: 1.4rem;
}

@media only screen and (max-width: 600px) {
    .faq-wrapper {
        margin-top: 2rem;
        display: grid;
        column-gap: 3rem;
        row-gap: 3rem;
        grid-template-columns: 1fr ;
    }
}