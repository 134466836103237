@font-face {
    font-family: 'Montserrat';
    src: url('./font/Montserrat.ttf');
}

* {
    margin: 0;
    padding: 0;
}

.App {
    font-family: 'Montserrat';
    color: #fff;
    background-image: url('./img/bg.png');
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    width: 100vw;
    position: relative;
}

.App {
    
    overflow-x: hidden !important;
}

@media only screen and (max-width: 600px) {
    .App {
        background-image: url('./img/bgmobile.png');
    }
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 1rem;
    margin-bottom: 3rem;
    max-width: 950px;
}

  
  #loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
