.header {
    display: flex;
    align-items: center;
    padding: 1rem 0rem;
    margin-bottom: 2rem;
    background-color: #9d4c55;
    width: 100%;
    justify-content: space-between;
}

.header .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 1rem;
}

.header .logo img {
    max-width: 30px;
}

.header .logo h1 {
    font-weight: 700;
    text-transform: none;
    margin-top: 0rewm;
    margin-bottom: 0rem;
    margin-left: .3rem;
    font-size: 1rem;
}

.burger-icon {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    width: 28px;
    gap: 6px;
    cursor: pointer;
}

.burger-icon div {
    width: 100%;
    height: 3px;
    background-color: white;
     border-radius: 99rem;
}

.menu-wrapper {
    background-color: #9d4c55;
    top: 0;
    width: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all .5s ease-in-out;
    padding-top: 2rem;
}

.X{
    width:40px;
    height:34px;
    position:absolute;
    top: 1rem;
    right: 1rem;
    border-radius:6px;
    cursor: pointer;
}

.X:before,.X:after{
    content:'';
    position:absolute;
    width:36px;
    height:4px;
    background-color:white;
    border-radius:2px;
    top:16px;
    box-shadow:0 0 2px 0 #ccc;
}

.X:before{
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    transform:rotate(45deg);
    left:2px;
}
.X:after{
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    transform:rotate(-45deg);
    right:2px;
}

.link-list {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 3rem;
}

.link-list a{
    color: white;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 1rem;
    font-size: 1.5rem;
}