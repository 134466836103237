.contact-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
}

.contact-form > textarea {
    resize: none;
    padding: .5rem;
    font-size: 16px;
    font-family: 'Montserrat';
}

.contact-form > input {
    padding: .5rem;
    margin-bottom: 1rem;
    font-size: 16px;
    font-family: 'Montserrat';
}

.contact-form > label {
    font-size: .9rem;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.contact-form button {
    width: auto;
    align-self: flex-end;
    margin-top: 1rem;
    padding: 1rem 1rem;
    border-radius: .3rem;
    border: none;
    outline: none;
    color: black;
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
}

.contact-form button:disabled {
    opacity: .6;
}

.loading-wrapper {
    border: 1px solid white;
    width: 100%;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    margin: 2rem 0;
    text-align: center;
    padding: 1rem;
}