
.event-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 2rem);
    max-width: 850px;
    margin-top: 3rem;
    cursor: pointer;
}

.event-description {
    white-space: pre-line;
    text-align: left;
    padding: .7rem;
    border: 1px solid #DADCCC;
    border-radius: 1rem;
    font-size: .9rem;
}

.cover {
    max-width: 900px;
    width: calc(100% - 1.6rem);
    border: .8rem #DADCCC solid;
    box-shadow: 1px 1px 18px 2px rgba(0,0,0,0.99);
}

.rotator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.event-starttime {
    opacity: 0.8; 
    font-weight: 600;
    color: #FFCF83;
    font-size: 1.4rem;
}

.more-infos-cta {
    color: #fff;
    text-decoration: underline;
    font-weight: 600;
    margin-top: 2rem;
    font-weight: 700;
}

.ticket-link {
    color: #fff;
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-weight: 700;
    font-size: 1.4rem;
}