.footer {
    margin-top: auto;
    padding: 2rem 1rem;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.3);
}

.footer .links {
    display: flex;
    gap: 1rem;
}

.footer .links a {
    color: #fff;
    text-decoration: none;
}

.footer .copy-right {
    margin-top: 1.3rem;
    opacity: .8;
}

.footer .copy-right a {
    color: #fff;
}