.page-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: calc(100% - 2rem);
    max-width: 500px;
}

h1 {
    font-weight: 600;
    text-transform: uppercase;
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-size: 1.6rem;
}

.title-deco {
    width: 100%;
}

.title-deco--reversed {
    transform: rotate(180deg);
}